export interface ISignInData {
    email: string
    password: string;
}

export interface IState {
    status: string;
    response: any;
    error: any;
}
export interface IPayload {
    email?: string;
    password?: string;
    signInMethod: ISignInType
}

// eslint-disable-next-line no-shadow
export enum ISignInType {
    EMAIL_PASSWORD = "EMAIL_PASSWORD",
    SOCIAL_AUTH = "SOCIAL_AUTH",
}

// eslint-disable-next-line no-shadow
export enum USER_ROLES {
    CLIENT = "CLIENT",
    ADMIN = "ADMIN",
    RECRUITER = "RECRUITER"
}
