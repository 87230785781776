import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#003350",
        },
        secondary: {
            main: "#fff",
        },
        error: {
            main: "#EF1010",
        },
        background: {
            default: "#fff",
        },
        colors: {
            black: "#000000",
            white: "#ffffff",
            greyText: "#9A9A9A",
            lightDark: "#363840",
            lightGrey: "#5D6180",
        },
    },
    typography: {
        fontFamily: ["Chivo", "sans-serif", "Roboto"].join(","),
        fontFamilySecondary: "Montserrat",
        fontFamilyRaleway: "Raleway",
    },
    spacing: 4,
});

export const text = {};

export default theme;
