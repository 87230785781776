import { createSlice } from "@reduxjs/toolkit";

import {
    doSignInAction
} from "./signin.actions";
import {
    IState
} from "./interface";

const initialState:IState = {
    status: "",
    response: {},
    error: {}
};

export const authSlice = createSlice({
    name: "signin",
    initialState,
    reducers: {
        increment: (state, action) => {
            // state.candidateList = [1, 2];
            // ✅ CORRECT: logs a plain JS copy of the current data
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(doSignInAction.pending, (state: IState, action) => {
                state.status = "pending";
            })
            .addCase(doSignInAction.fulfilled, (state: IState, action: any) => {
                state.status = "fulfilled";
                state.error = {};
                state.response = action.payload;
            })
            .addCase(doSignInAction.rejected, (state: IState, action: any) => {
                state.status = "rejected";
                state.response = {};
                state.error = action.error;
            });
    }
});

export const { increment } = authSlice.actions;
export default authSlice.reducer;
