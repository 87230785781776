import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {},
    form: {
        width: 465,
        "& .MuiGrid-grid-sm-auto": {
            width: 222,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            }
        }
    },
    link: {
        fontSize: 17,
        fontWeight: 400,
        textDecoration: "none",
        color: theme.palette.colors.black,
        marginLeft: 6
    },
    linkText: {
        color: theme.palette.colors.greyText,
        fontSize: 17,
        textAlign: "center",
        fontWeight: 400,
    },
    inputRoot: {

    },
    errorBorder: {
    },
    outlineInput: {},
    labelText: {
        fontSize: 17,
        fontWeight: 400,
        fontFamily: theme.typography.fontFamily,
        lineHeight: "28px",
        marginBottom: 6
    },
    inputField: {},
    checkboxContainer: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        "& .privacy": {
            width: 380
        },
        "& .privacy > *": {
            color: theme.palette.colors.lightGrey,
        },
        "& .Mui-checked": {
            "& svg": {
                fill: `${theme.palette.colors.lightDark} !important`,
            },
        },
        "& .MuiSvgIcon-root": {
            fontSize: 26,
        },
    },
    submit: {
        margin: "25px 0px 18px",
        height: 50,
        color: theme.palette.colors.white,
        fontSize: 20,
        fontWeight: 400,
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.colors.black,
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#363840",
            boxShadow: "none",
        },

    }
}));

export default useStyles;
