import { createAsyncThunk } from "@reduxjs/toolkit";
import { functions, firebase, firestore } from "../../../firebase";
import {
    ISignUpData, IRecruiter, ISignUpType,
    USER_ROLES
} from "./interface";
import { auth } from "../../../firebase";
import { toast } from "react-toastify";

export const doSignUpAction = createAsyncThunk("signup", async (payload: ISignUpData) => {
    try {
        if (payload.signUpMethod === ISignUpType.EMAIL_PASSWORD) {
            const timestamp = firebase.firestore.FieldValue.serverTimestamp();
            const signUp: any = functions.httpsCallable("auth-signUpWithEmailPassword");
            const {
                email = "",
                password = "",
                candidateId = "",
                firstName = "",
                lastName = "",
                role
            } = payload;
            const signUpAuthData: ISignUpData = {
                email,
                password,
                firstName,
                lastName,
                candidateId,
                role
            };
            const signUpResp = signUp(signUpAuthData);
            return signUpResp.then(async (res: any) => {
                if (res.data.result) {
                    functions.httpsCallable("auth-funcSignInWithEmailAndPassword")({
                        userId: res.data.uid,
                        role: USER_ROLES.CLIENT
                    })
                        .then((resp: any) => {
                            toast.success("Signup successfully");
                            if (role === USER_ROLES.CLIENT) {
                                window.location.replace(`
                                        ${process.env.REACT_APP_CLIENT_SIGN_UP_REDIRECT_URL}?token=${resp.data.token}`
                                    || "");
                                return {
                                    ...res.data,
                                    success: true
                                };
                            } if (role === USER_ROLES.RECRUITER) {
                                window.location.replace(`
                                ${process.env.REACT_APP_RECRUITER_BASE_URL}/approval-pending/?token=${resp.data.token}`
                            || "");
                            }
                        });
                } else {
                    toast.error(res.data.errorInfo.message || "Signup failed!");
                    return {
                        ...res.data,
                        success: false
                    };
                }
            })
                .catch((error: any) => {
                    console.log("Error", error);
                    toast.error("Error signing up");
                    return error;
                });
        }
        if (payload.signUpMethod === ISignUpType.SOCIAL_AUTH) {
            const provider = new firebase.auth.GoogleAuthProvider();
            const resp = auth.signInWithPopup(provider);
            return resp.then((signInResp: any) => functions.httpsCallable("auth-funcSignInWithEmailAndPassword")({
                userId: signInResp.user?.uid
            })
                .then(async (serverResp) => {
                    if (serverResp.data.is_onboarded) {
                        toast.success("Sign Up successful!");
                        setTimeout(() => {
                            window.location
                                .replace(`${process.env.REACT_APP_CLIENT_BASE_URL}?token=${serverResp.data.token}`
                                || "");
                        });
                    } else {
                        const newUser = await functions.httpsCallable("auth-signUpWithGoogle")({
                            uid: signInResp.user.uid,
                            email: signInResp.user.email,
                            firstName: signInResp.user?.displayName.split(" ")[0] || "",
                            lastName: signInResp.user?.displayName.split(" ")[1] || "",
                            role: payload.role
                        });
                        if (newUser.data.result) {
                            toast.success("Signup successful!");
                            setTimeout(() => {
                                window.location.replace(`
                                    ${process.env.REACT_APP_CLIENT_SIGN_UP_REDIRECT_URL}?token=${serverResp.data.token}`
                                    || "");
                            });
                        } else {
                            toast.error("Signup failed!");
                            return {
                                error: newUser
                            };
                        }
                    }
                }))
                .catch((err: any) => ({
                    err
                }));
        }
    } catch (error) {
        return error;
    }
});

export const doSignInAction = createAsyncThunk("signIn", async (payload: any) => {
    try {
        if (payload.email) {
            const response = auth.signInWithEmailAndPassword(payload.email, payload.password);
            return response;
        }
    } catch (error) {
        return error;
    }
});
