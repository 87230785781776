import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { CustomInput } from "./CustomInput";
import { FieldErrors, FieldValues } from "react-hook-form";

export interface IPasswordProps {
  register: any;
  errors: FieldErrors<FieldValues>;
  size?: "medium" | "small";
  autoComplete?: string;
}

export const PasswordField = ({
    register,
    errors,
    size,
    autoComplete,
}: IPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const viewPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <CustomInput
              fullWidth
              variant="outlined"
              margin="normal"
              name="password"
              placeholder="Password"
              size={size}
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete={autoComplete || "off"}
              inputRef={register}
              InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={viewPassword}
                            onMouseDown={viewPassword}
                          >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      </InputAdornment>
                  ),
              }}
            />
            {errors?.password && (
            <Box color="error.main">{(errors.password as any).message}</Box>
            )}
        </>
    );
};
