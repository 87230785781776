import { RouteComponentProps } from "@reach/router";
import React, { FC } from "react";
import useStyles from "../Terms/styles";
import NavigationMenu from "../../Components/Menu";
import Text, { textEnum } from "../../Components/Text";

const Privacy: FC<RouteComponentProps> = () => {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <NavigationMenu />

            <div className={styles.contentArea}>
                <Text
                  style={{
                      textAlign: "center",
                      fontWeight: 800,
                      fontSize: 32,
                  }}
                  className={styles.titleMain}
                  textType={textEnum.small}
                >
                    ACELR8 Ltd Privacy Policy
                </Text>

                <span className={styles.subTitle}>
                    ACELR8 Ltd is ACELR8 Ltd. This privacy policy will explain how our
                    organization uses the personal data we collect from you when you use
                    our website.
                </span>

                <div>
                    <span className="title"> Topics: </span>
                    <p> • What data do we collect?</p>
                    <p> • How do we collect your data? </p>
                    <p> • How will we use your data?</p>
                    <p>• Who receives your data? </p>
                    <p>• What are your data protection rights?</p>
                    <p> • How do we use cookies? </p>
                    <p> • What types of cookies do we use? </p>
                    <p>• How to manage cookies and similar technologies </p>
                    <p>• How to contact us</p>
                </div>

                <div>
                    <span className="title">How do we collect your data?</span>
                    <p>
                        You directly provide ACELR8 Ltd with most of the data we collect. We
                        collect data and process data when you:
                    </p>
                </div>

                <div>
                    <ul>
                        <li>
                            Register online or place an order for any of our products or
                            services
                        </li>
                        <li>
                            Voluntarily complete a customer survey or provide feedback on any
                            of our message boards or via email
                        </li>
                        <li> Use or view our website via your browser </li>
                        <li>Join our events</li>
                        <li> Join our webinars</li>
                        <li>Subscribe to our newsletter to receive updates</li>
                    </ul>

                    <p>
                        ACELR8 Ltd may also receive some of your data indirectly – when
                        you’re visiting our profiles on external sites like social networks
                        - from the following sources: Google, Linkedin, Twitter, Facebook
                    </p>
                </div>

                <div>
                    <span className="title"> How will we use your data?</span>
                    <p>
                        ACELR8 Ltd collects your data so that we can: Process your order and
                        manage your account. Email you with content. Contact you about our
                        services. The collection and use of your data is either based on the
                        performance of a contract that you are part of or based on your
                        voluntary consent. If it is based on consent, you can always contact
                        us to revoke that consent.
                    </p>
                </div>

                <div>
                    <span className="title"> Who receives your data?</span>
                    <p>
                        ACELR8 Ltd relies on audited service providers, to whom we might
                        send your data to if it is necessary for the performance of our
                        services. We available safeguards. We will keep your contact data
                        only for a strictly necessary time period, e.g. as long as you are
                        our customer or as long as you do not opt-out. If you subscribe to
                        our newsletter through our website or register on external sites
                        such as a webinar, we will send you information about products and
                        services. You may always opt-out at any time. We use Mailchimp, for
                        our newsletters and e-mail campaigns. Mailchimp is a US service
                        provider, with whom we have concluded all available safeguards. When
                        joining our events, we use well known platforms like Meetup or
                        securely store your data in our customer management system
                        Mailchimp. Webinars are hosted on Livestorm. Livestorm is a US
                        service provider, with whom we have concluded all available
                        safeguards. If you join through an external platform, you will most
                        likely accept their terms and services, so please read carefully. We
                        will however always ensure that we choose the best service provider
                        together with our data protection officer. This includes all
                        required assessments and agreements to safeguard your personal data.
                    </p>
                </div>

                <div>
                    <span className="title">
                        What happens to your data if you apply for a job?
                    </span>
                    <p>
                        Interested applicants can apply through the “Careers” option we
                        provide on our website. We use the online hiring tool Workable to
                        manage new applicants. If you apply for an open role, you will be
                        redirected to aWorkable webpage that is managed by us. Workable is a
                        US service provider, with whom we have concluded all available
                        safeguards. We will keep your applicant data for six months, except
                        in those cases where you have given consent for extended storage
                        (e.g. talent pool).
                    </p>
                </div>

                <div>
                    <span className="title">What are your data protection rights?</span>
                    <p>
                        ACELR8 Ltd would like to make sure you are fully aware of all of
                        your data protection rights. Every user is entitled to the
                        following:
                    </p>

                    <ul>
                        <li>
                            The right to access – You have the right to request ACELR8 Ltd for
                            copies of your personal data. We may charge you a small fee for
                            this service.
                        </li>
                        <li>
                            The right to rectification – You have the right to request that
                            ACELR8 Ltd correct any information you believe is inaccurate. You
                            also have the right to request ACELR8 Ltd to complete the
                            information you believe is incomplete.
                        </li>
                        <li>
                            The right to erasure – You have the right to ask ACELR8 Ltd to
                            delete your data at any point in time. Please keep in mind that we
                            might have to store some data, e.g. billing or tax related, for up
                            to six or even ten years. This only applies to data that falls
                            under legal data retention obligations.
                        </li>
                        <li>
                            The right to restrict processing – You have the right to request
                            that ACELR8 Ltd restrict the processing of your personal data,
                            under certain conditions.
                        </li>
                        <li>
                            The right to object to processing – You have the right to object
                            to ACELR8 Ltd’s processing of your personal data, under certain
                            conditions.
                        </li>
                        <li>
                            The right to data portability – You have the right to request that
                            ACELR8 Ltd transfer the data that we have collected to another
                            organization, or directly to you, under certain conditions.
                        </li>
                        <li>
                            The right to file a complaint with the supervisory authority – You
                            have the right to contact your local supervisory authority and
                            file a complaint. If you make a request, we have one month to
                            respond to you. We have also designated a data protection officer.
                            If you would like to exercise any of these rights, please contact
                            us at our email: hello@acelr8.com
                        </li>
                    </ul>
                    <p>
                        <a className={styles.link} href="tel:+442071938069">
                            Call us: +44 2071 938069
                        </a>
                        Or write to us at: Dieffenbachstraße 36, 10967 Berlin
                    </p>
                </div>

                <div>
                    <span className="title"> How do we use cookies?</span>
                    <p>
                        ACELR8 Ltd uses cookies and similar technologies. Cookies are text
                        files placed on your computer to collect browser information and
                        visitor behavior information. When you visit our websites, we may
                        collect information from you automatically through cookies or
                        similar technology in a range of ways to improve your experience on
                        our website, including:
                    </p>
                    <ul>
                        <li> Understanding how you use our website</li>
                        <li> Understanding where you work </li>
                    </ul>
                </div>

                <div>
                    <span className="title"> What types of cookies do we use? </span>
                    <p>
                        There are a number of different types of cookies, however, our
                        website uses: Functionality – ACELR8 Ltd uses these cookies so that
                        we recognize you on our website and remember your previously
                        selected preferences. These could include what language you prefer
                        and location you are in. A mix of first-party and third-party
                        cookies are used.
                    </p>
                    <p>
                        Advertising – ACELR8 Ltd uses these cookies to collect information
                        about your visit to our website, the content you viewed, the links
                        you followed and information about your browser, device, and your IP
                        address. ACELR8 Ltd sometimes shares some limited aspects of this
                        data with third parties for advertising purposes. We may also share
                        online data collected through cookies with our advertising partners.
                        This means that when you visit another website, you may be shown
                        advertising based on your browsing patterns on our website.
                    </p>
                </div>

                <div>
                    <span className="title">
                        How to manage cookies and similar technologies?
                    </span>
                    <p>
                        You can set your browser not to accept cookies. Depending on the
                        technologies we currently use, you can also change settings through
                        our cookie banner. However, in a few cases, some of our website
                        features may not function as a result.
                    </p>
                </div>

                <div className={styles.contactSection}>
                    <span className="title"> How to contact us </span>
                    <p>
                        If you have any questions about ACELR8 Ltd’s privacy policy, the
                        data we hold on you, or you would like to exercise one of your data
                        protection rights, please do not hesitate to contact us.
                    </p>

                    <a className={styles.link} href="mailto:hello@acelr8.com">
                        Email us at: hello@acelr8.com
                    </a>
                    <a className={styles.link} href="tel:+442071938069">
                        Call us: +44 2071 938069
                    </a>

                    <p>Or write to us at: Dieffenbachstraße 36, 10967 Berlin</p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
