import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    InputStyles: {
        "& div": {
            border: "1px solid  rgb(229, 115, 115)",
            "&:focus": {
                border: "1px solid  rgb(229, 115, 115)",
                transition: theme.transitions.create(["border"]),
            }
        }
    },
    inputRoot: {
        fontSize: theme.spacing(3),
    },
    outlineInput: {
        padding: theme.spacing(3),
    },
    inputLabel: {
        color: "#2F2824",
        textTransform: "capitalize",
        display: "flex",
        fontWeight: 600,
        fontSize: "14px",
        transform: "initial",
    },
    asterik: {
        color: "red",
        paddingLeft: "2px",
    },
    inputContainer: {
        marginTop: "10px",
        position: "relative"
    },

    iconedInput: {
        "& input": {
            paddingLeft: "2.5em",
        },
    },
    errorBorder: {
        border: "1px solid  rgb(229, 115, 115) !important",
        "&:focus": {
            border: "1px solid  rgb(229, 115, 115) !important",
            transition: theme.transitions.create(["border"]),
        }
    },
}));

export default useStyles;
