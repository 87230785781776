import React, { FC, MouseEventHandler, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, RouteComponentProps } from "@reach/router";
import Layout from "../../Components/Layout";
import { Box, Checkbox, Grid } from "@material-ui/core";
import useStyles from "./styles";
import {
    ROUTE_UNAUTHENTICATED_LOGIN,
    ROUTE_TERMS_AND_CONDITIONS,
    ROUTE_PRIVACY_POLICY,
    ROUTE_MARKETING_AND_COMMUNICATIONS,
} from "../../routes";
import GoogleButton from "../../Components/GoogleButton";
import { CustomInput } from "../../Components/Inputs/CustomInput";
import { SignUpSchema } from "../../Utils/FormValidationSchema";
import { useForm } from "react-hook-form";
import { EmailField } from "../../Components/Inputs/EmailField";
import { PasswordField } from "../../Components/Inputs/PasswordField";
import { SubmitButton } from "../../Components/Buttons/Button";
import { doSignUpAction } from "../../redux/services/signup/signup.actions";
import { ISignUpType, USER_ROLES } from "../../redux/services/signup/interface";
import { RootState } from "../../redux/store";

const initialState = {
    isChecked: false,
    isRecruiter: false,
};

const RecruiterSignUp: FC<RouteComponentProps> = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = React.useState(initialState);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const signUpData: any = useSelector(
        (reduxState: RootState) => reduxState.signup
    );
    const reduxDispatch = useDispatch();

    const styles = useStyles();

    const { register, handleSubmit, errors } = useForm({
        validationSchema: SignUpSchema,
    });

    const onCheckboxChange = () => {
        setState((st) => ({
            ...st,
            isChecked: !state.isChecked,
        }));
    };

    const onCheckRecruiter = () => {
        setState((st) => ({
            ...st,
            isRecruiter: !state.isRecruiter,
        }));
    };

    const onSubmit = async (formData: any) => {
        setIsSubmitting(true);
        await reduxDispatch(
            doSignUpAction({
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                password: formData.password,
                signUpMethod: ISignUpType.EMAIL_PASSWORD,
                role: USER_ROLES.RECRUITER
            })
        );
        setIsSubmitting(false);
    };

    const handleClick: MouseEventHandler = async () => {
        await reduxDispatch(
            doSignUpAction({
                signUpMethod: ISignUpType.SOCIAL_AUTH,
                role: USER_ROLES.RECRUITER
            })
        );
    };

    return (
        <Layout
          style={{ marginBottom: 50 }}
          title="Sign up
as a Talent Partner / Sourcer"
        >
            <div>
                <GoogleButton
                  handleClick={handleClick}
                  text="Sign up with Google"
                  loading={isLoading}
                />
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                  className={styles.form}
                >
                    <Grid
                      style={{ marginBottom: 18 }}
                      className={styles.inputField}
                      item
                      xs={12}
                    >
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                          spacing={1}
                        >
                            <Grid item xs={12} sm="auto">
                                <Grid item xs={12}>
                                    <Box className={styles.labelText}>
                                        First Name
                                        <span style={{ color: "red", paddingLeft: "2px" }}>
                                            *
                                        </span>
                                    </Box>
                                </Grid>
                                <CustomInput
                                  fullWidth
                                  id="firstName"
                                  placeholder="Jane"
                                  autoComplete="off"
                                  name="firstName"
                                  autoFocus
                                  inputRef={register}
                                  classes={{
                                      root: styles.inputRoot,
                                      input: `${styles.outlineInput} ${
                                          errors.firstName ? styles.errorBorder : ""
                                      }`,
                                  }}
                                />
                                {errors.firstName && (
                                <Box color="error.main">
                                    {(errors.firstName as any).message}
                                </Box>
                                )}
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <Grid item xs={12}>
                                    <Box className={styles.labelText}>
                                        Last Name
                                        <span style={{ color: "red", paddingLeft: "2px" }}>
                                            *
                                        </span>
                                    </Box>
                                </Grid>
                                <CustomInput
                                  fullWidth
                                  id="lastName"
                                  placeholder="Doe"
                                  name="lastName"
                                  autoComplete="off"
                                  inputRef={register}
                                  classes={{
                                      root: styles.inputRoot,
                                      input: `${styles.outlineInput} ${
                                          errors.lastName ? styles.errorBorder : ""
                                      }`,
                                  }}
                                />
                                {errors.lastName && (
                                <Box color="error.main">
                                    {(errors.lastName as any).message}
                                </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid style={{ marginBottom: 18 }} container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                              className={styles.labelText}
                              fontWeight="fontWeightBold"
                              fontSize={10}
                            >
                                Work Email
                                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                            </Box>
                        </Grid>
                        <EmailField
                          placeHolder="jane@work.com"
                          register={register}
                          errors={errors}
                        />
                    </Grid>

                    <Grid style={{ marginBottom: 26 }} container spacing={1}>
                        <Grid item xs={12}>
                            <Box className={styles.labelText}>
                                Password
                                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                            </Box>
                        </Grid>
                        <PasswordField
                          autoComplete="new-password"
                          register={register}
                          errors={errors}
                        />
                    </Grid>

                    <Grid container spacing={2}>
                        <div className={styles.checkboxContainer}>
                            <Checkbox
                              required
                              disableRipple
                              checked={state.isChecked}
                              onChange={onCheckboxChange}
                              color="primary"
                            />
                            <div className="privacy">
                                <span>I agree to ACELR8's </span>
                                <Link to={ROUTE_PRIVACY_POLICY}>Privacy Policy</Link>
                                <span> and I agree to receive marketing communications.</span>
                                {/* <span>, </span>
                                <Link to={ROUTE_TERMS_AND_CONDITIONS}>
                                    Terms & Conditions
                                </Link>
                                <span> and </span>
                                <Link to={ROUTE_MARKETING_AND_COMMUNICATIONS}>
                                    Marketing Communications.
                                </Link> */}
                                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                            </div>
                        </div>
                    </Grid>

                    {/* <Grid container spacing={2}>
            <div className={styles.checkboxContainer}>
              <Checkbox
                required
                disableRipple
                checked={state.isRecruiter}
                onChange={onCheckRecruiter}
                color="primary"
              />
              <span> Sign up as a recruiter </span>
            </div>
          </Grid> */}
                    <Grid container spacing={2}>
                        <Grid container>
                            <SubmitButton
                              className={styles.submit}
                              text="Sign Up"
                              loading={isSubmitting}
                            />

                            <Grid style={{ justifyContent: "center" }} container>
                                <div>
                                    <span className={styles.linkText}>Already a user?</span>
                                    <Link
                                      to={ROUTE_UNAUTHENTICATED_LOGIN}
                                      className={styles.link}
                                    >
                                        Sign in
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Layout>
    );
};

export default RecruiterSignUp;
