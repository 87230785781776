import React from "react";
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const CompassButton = styled((props: any) => <Button {...props} />)({
    height: 50,
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "7px",
});
