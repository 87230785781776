import { combineReducers } from "redux";

import signup from "./services/signup/signup";
import signin from "./services/signin/signin";
import forgotPassword from "./services/forgot-password/forgot-password";

const rootReducer = combineReducers({
    signup,
    signin,
    forgotPassword
});

export default rootReducer;

