import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth, firebase, functions } from "../../../firebase";
import { toast } from "react-toastify";
import { IPayload, ISignInType, USER_ROLES } from "./interface";

export const doSignInAction = createAsyncThunk("signIn", async (payload: IPayload) => {
    try {
        if (payload.signInMethod === ISignInType.EMAIL_PASSWORD) {
            const { email = "", password = "" } = payload;
            const response = auth.signInWithEmailAndPassword(email, password);
            return response.then(async (res) => {
                const currUser = await firebase?.auth()?.currentUser?.getIdTokenResult();
                if (currUser?.claims.role === USER_ROLES.CLIENT) {
                    if (!currUser.claims.isClient) {
                        toast.warn("You do not have access to this panel");
                        return;
                    }
                    functions.httpsCallable("auth-funcSignInWithEmailAndPassword")({
                        userId: res.user?.uid,
                        role: USER_ROLES.CLIENT
                    })
                        .then(async (resp) => {
                            toast.success("Login successful!");
                            if (resp.data.is_onboarded) {
                                setTimeout(() => {
                                    window.location
                                        .replace(`${process.env.REACT_APP_CLIENT_BASE_URL}?token=${resp.data.token}`
                                        || "");
                                });
                            } else {
                                setTimeout(() => {
                                    window.location.replace(`
                                        ${process.env.REACT_APP_CLIENT_SIGN_UP_REDIRECT_URL}?token=${resp.data.token}`
                                        || "");
                                });
                            }
                        });
                }
                if (currUser?.claims.role === USER_ROLES.RECRUITER) {
                    if (!currUser.claims.isRecruiter) {
                        toast.warn("You do not have access to this panel");
                        return;
                    }
                    functions.httpsCallable("auth-funcSignInWithEmailAndPassword")({
                        userId: res.user?.uid,
                        role: USER_ROLES.RECRUITER
                    })
                        .then(async (resp) => {
                            toast.success("Login successful!");
                            if (resp.data.is_active === "deactivated" || !resp.data.is_active) {
                                window.location
                                    // eslint-disable-next-line max-len
                                    .replace(`${process.env.REACT_APP_RECRUITER_BASE_URL}/approval-pending/?token=${resp.data.token}`
                                        || "");
                            } else if (resp.data.is_active === true) {
                                if (resp.data.on_boarding_stage === "fully_onboarded"
                                    || resp.data.on_boarding_stage === "full_company_onboarding") {
                                    window.location
                                    // eslint-disable-next-line max-len
                                        .replace(`${process.env.REACT_APP_RECRUITER_BASE_URL}/active-jobs/?token=${resp.data.token}`
                                        || "");
                                } else if (resp.data.on_boarding_stage) {
                                    window.location
                                    // eslint-disable-next-line max-len
                                        .replace(`${process.env.REACT_APP_RECRUITER_BASE_URL}${resp.data.on_boarding_stage}/?token=${resp.data.token}`
                                                || "");
                                } else {
                                    window.location
                                        // eslint-disable-next-line max-len
                                        .replace(`${process.env.REACT_APP_RECRUITER_BASE_URL}/approval-done/?token=${resp.data.token}`
                                            || "");
                                }
                            }
                        });
                }
                if (currUser?.claims.isAdmin || currUser?.claims.role === USER_ROLES.ADMIN) {
                    console.log("ADMIN ROLE");
                    toast.success("ADMIN ROLE, WORK IN PROGRESS, but successful!");
                }
            }).catch((error) => {
                toast.error("Login failed/ Invalid email or password!");
                return {
                    error
                };
            });
        }
        if (payload.signInMethod === ISignInType.SOCIAL_AUTH) {
            const provider = new firebase.auth.GoogleAuthProvider();
            const resp = auth.signInWithPopup(provider);
            const currUser = await firebase?.auth()?.currentUser?.getIdTokenResult();
            if (currUser?.claims.role === USER_ROLES.CLIENT) {
                return resp.then((signInResp: any) => functions.httpsCallable("auth-funcSignInWithEmailAndPassword")({
                    userId: signInResp.user?.uid,
                    role: USER_ROLES.CLIENT
                })
                    .then(async (serverResp) => {
                        if (serverResp.data.is_onboarded) {
                            toast.success("Login successful!");
                            setTimeout(() => {
                                window.location
                                    .replace(`${process.env.REACT_APP_CLIENT_BASE_URL}?token=${serverResp.data.token}`
                                    || "");
                            });
                        } else {
                            const newUser = await functions.httpsCallable("auth-signUpWithGoogle")({
                                uid: signInResp.user.uid,
                                email: signInResp.user.email,
                                firstName: signInResp.user?.displayName.split(" ")[0] || "",
                                lastName: signInResp.user?.displayName.split(" ")[1] || "",
                            });
                            if (newUser.data.result) {
                                toast.success("Login successful!");
                                setTimeout(() => {
                                    // eslint-disable-next-line max-len
                                    window.location.replace(`${process.env.REACT_APP_CLIENT_SIGN_UP_REDIRECT_URL}?token=${serverResp.data.token}`
                                        || "");
                                });
                            } else {
                                toast.error("Login failed!");
                                return {
                                    error: newUser
                                };
                            }
                        }
                    }))
                    .catch((err: any) => ({
                        err
                    }));
            }
            if (currUser?.claims.role === USER_ROLES.RECRUITER) {
                return resp.then((signInResp: any) => functions.httpsCallable("auth-funcSignInWithEmailAndPassword")({
                    userId: signInResp.user?.uid,
                    role: USER_ROLES.RECRUITER
                })
                    .then(async (serverResp) => {
                        if (serverResp.data.is_active === "deactivated") {
                            window.location
                                // eslint-disable-next-line max-len
                                .replace(`${process.env.REACT_APP_RECRUITER_BASE_URL}/approval-pending/?token=${serverResp.data.token}`
                                    || "");
                        } else if (serverResp.data.is_active === true) {
                            if (serverResp.data.on_boarding_stage) {
                                window.location
                                    // eslint-disable-next-line max-len
                                    .replace(`${process.env.REACT_APP_RECRUITER_BASE_URL}${serverResp.data.on_boarding_stage}/?token=${serverResp.data.token}`
                                        || "");
                            } else {
                                window.location
                                // eslint-disable-next-line max-len
                                    .replace(`${process.env.REACT_APP_RECRUITER_BASE_URL}/approval-done/?token=${serverResp.data.token}`
                                    || "");
                            }
                        }
                    }))
                    .catch((err: any) => ({
                        err
                    }));
            }
        }
    } catch (error) {
        toast.error("Login failed!");
        return error;
    }
});
