import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    form: {
        width: 465,
        "& .MuiGrid-grid-sm-auto": {
            width: 212,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            }
        }
    },
    link: {
        fontSize: 17,
        fontWeight: 400,
        textDecoration: "none",
        color: theme.palette.colors.black,
        marginLeft: 6
    },
    linkText: {
        color: theme.palette.colors.greyText,
        fontSize: 17,
        textAlign: "center",
        fontWeight: 400,
    },
    labelText: {
        fontSize: 17,
        fontWeight: 400,
        fontFamily: theme.typography.fontFamily,
        lineHeight: "28px",
        marginBottom: 12
    },
    submit: {
        margin: "25px 0px 18px",
        height: 50,
        color: theme.palette.colors.white,
        fontSize: 20,
        fontFamily: theme.typography.fontFamily,
        backgroundColor: `${theme.palette.colors.black} !important`,
        textTransform: "none",
        "& .MuiButton-label": {
            fontWeight: 400
        },
        "&:hover": {
            backgroundColor: `${theme.palette.colors.lightDark} !important`,
            boxShadow: "none !important",
        },
    }
}));

export default useStyles;
