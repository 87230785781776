/* eslint-disable import/prefer-default-export */
import React from "react";
import Box from "@material-ui/core/Box";
import { CustomInput } from "./CustomInput";
import { FieldErrors, FieldValues } from "react-hook-form";

export interface IEmailFieldProps {
  register: any;
  errors: FieldErrors<FieldValues>;
  autoFocus?: boolean;
  outlinedInput?: boolean;
  size?: "medium" | "small";
  placeHolder?: string;
}

export const EmailField = ({
    register,
    errors,
    autoFocus,
    size,
    placeHolder,
}: IEmailFieldProps) => (
    <>
        <CustomInput
          variant="outlined"
          size={size || "medium"}
          fullWidth
          id="email"
          placeholder={placeHolder || "Email Address"}
          name="email"
          autoComplete="off"
          inputRef={register}
          autoFocus={autoFocus}
        />
        {errors?.email && (
        <Box color="error.main">{(errors.email as any).message}</Box>
        )}
    </>
);
