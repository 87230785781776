import React, { FC } from "react";
import useStyles from "./styles";
import { ReactComponent as IntroImage } from "../../Assets/intro.svg";
import { ReactComponent as Logo } from "../../Assets/Logo.svg";
import { ROUTE_UNAUTHENTICATED_LOGIN } from "../../routes";
import {
    useNavigate,
} from "@reach/router";

interface ILayoutProps {
  title: string;
  style?: any
}

const Layout: FC<ILayoutProps> = ({ children, title, style }) => {
    const styles = useStyles();
    const navigate = useNavigate();

    const handleClick = () => navigate(ROUTE_UNAUTHENTICATED_LOGIN);

    return (
        <div className={styles.root}>
            <div className={styles.contentSection}>
                <div
                  onClick={handleClick}
                  onKeyDown={() => console.log("key down")}
                  role="link"
                  style={{ ...style, cursor: "pointer" }}
                  className={styles.logoContainer}
                  tabIndex={0}
                >
                    <Logo />
                </div>
                <div className={styles.content}>
                    <span className={styles.title}>{title}</span>
                    {children}
                </div>
            </div>
            <div className={styles.imageSection}>
                <IntroImage />
            </div>
        </div>
    );
};

export default Layout;
