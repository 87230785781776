import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    mainMenu: {
        boxShadow: "0 4px 14px rgba(0,0,0,0.15)",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 99,
        overflow: "hidden",
        backgroundColor: theme.palette.colors.white
    },
    root: {
        width: "80%",
        display: "flex",
        justifyContent: "space-between",
        height: 80,
        zIndex: 998,
        margin: "0 auto",
        alignItems: "center"
    },
    logoContainer: {
        cursor: "pointer"
    },
    linksContainer: {},
    link: {
        textDecoration: "none",
        fontSize: 16,
        fontWeight: 500,
        fontFamily: theme.typography.fontFamilyRaleway,
        borderTopLeftRadius: 25,
        borderBottomRightRadius: 25,
        backgroundColor: theme.palette.colors.black,
        padding: "15px 30px",
        color: theme.palette.colors.white,
        textTransform: "uppercase",
    }
}));

export default useStyles;
