import React, { FC } from "react";
import Layout from "../../Components/Layout";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import { Box, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../../Components/Buttons/Button";
import { EmailField } from "../../Components/Inputs/EmailField";
import { Link, RouteComponentProps } from "@reach/router";
import { ROUTE_UNAUTHENTICATED_LOGIN } from "../../routes";
import { ForgotPasswordSchema } from "../../Utils/FormValidationSchema";
import { doForgotPasswordAction } from "../../redux/services/forgot-password/forgot-password.actions";
import { RootState } from "../../redux/store";

const ResetPassword: FC<RouteComponentProps> = () => {
    const styles = useStyles();
    const reduxDispatch = useDispatch();

    const forgotPassword: any = useSelector(
        (reduxState: RootState) => reduxState.forgotPassword
    );

    const { register, handleSubmit, errors } = useForm({
        validationSchema: ForgotPasswordSchema,
    });

    const onSubmit = async (data: any) => {
        await reduxDispatch(
            doForgotPasswordAction({
                email: data.email,
            })
        );
    };

    return (
        <Layout title="Forgotten password">
            <div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                  className={styles.form}
                >
                    <Grid style={{ marginBottom: 18 }} container spacing={1}>
                        <Grid item xs={12}>
                            <Box className={styles.labelText}>Email Address</Box>
                        </Grid>
                        <EmailField
                          placeHolder="jane@work.com"
                          register={register}
                          errors={errors}
                        />
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid container>
                            <SubmitButton
                              loading={forgotPassword.status === "pending"}
                              className={styles.submit}
                              text="Reset password"
                            />

                            <Grid style={{ marginTop: "18px" }} container>
                                <div>
                                    <span className={styles.linkText}>Back to</span>
                                    <Link
                                      to={ROUTE_UNAUTHENTICATED_LOGIN}
                                      className={styles.link}
                                    >
                                        Sign in
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Layout>
    );
};

export default ResetPassword;
