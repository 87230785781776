import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        width: "100%",
        overflow: "auto",
        display: "flex",
        justifyContent: "space-between",
    },
    contentSection: {
        padding: "56px 132px",
    },
    content: {
        width: 456,
    },
    imageSection: {
        overflow: "hidden"
    },
    logoContainer: {
        marginBottom: 97,
    },
    title: {
        fontWeight: 600,
        fontSize: 26,
        lineHeight: "30px",
        fontFamily: theme.typography.fontFamily,
        marginBottom: 30,
        display: "inline-block",
        whiteSpace: "pre-line"
    },
}));

export default useStyles;
