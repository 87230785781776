// eslint-disable jsx-props-no-spreading

import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import useStyles from "./styles";

const InputComponent = (props: any) => {
    const classes = useStyles();
    return (
        <InputBase
          className={props.errors ? classes.InputStyles : ""}
          {...props}
          placeholder={`${props.placeholder}${props.required ? "*" : ""}`}
        />
    );
};

export const CustomInput = withStyles((theme: Theme) => createStyles({
    root: {
        fontSize: 14,
        fontWeight: 500,
    },
    input: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 8,
        position: "relative",
        border: "1px solid #AEAEB2",
        fontSize: 14,
        fontWeight: 500,
        height: 50,
        padding: "0 26px 0 12px",
        transition: theme.transitions.create(["border"]),
        "&:focus": {
            borderRadius: 8,
            border: "1px solid #D8D8D8",
            transition: theme.transitions.create(["border"]),
        },
        "&:hover": {
            border: "1px solid #363840",
            transition: theme.transitions.create(["border"]),
        },
    },
}))(InputComponent);
