import React, { ReactNode } from "react";
import useStyles from "./style";

interface IProps {
  children: ReactNode;
  className?: string;
  style?: any;
  textType?: textEnum;
  id?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
}

// eslint-disable-next-line no-shadow
export enum textEnum {
  large = "lg",
  small = "sm",
  title = "title",
}

const Text = (props: IProps) => {
    const classes = useStyles();
    const {
        children,
        className = "",
        style = {},
        textType,
        id,
        onMouseEnter,
        onMouseLeave,
    } = props;

    const renderText = () => {
        if (props.textType === "lg") {
            return (
                <p
          // {...props}
                  style={style}
                  className={`${classes.container} ${classes.lgContainer} ${
                      className || ""
                  }`}
                  id={id}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                    {children}
                </p>
            );
        }
        if (props.textType === "title") {
            return (
                <title
                  style={style}
                  className={`${classes.container} ${classes.lgContainer} ${
                      className || ""
                  }`}
                  id={id}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                    {children}
                </title>
            );
        }
        return (
            <p
              style={style}
              className={`${classes.container} ${className || ""}`}
              id={id}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
                {children}
            </p>
        );
    };
    return renderText();
};

export default Text;
