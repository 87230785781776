import React, { FC, MouseEventHandler, useState } from "react";
import Layout from "../../Components/Layout";
import useStyles from "./styles";
import { Box, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { SubmitButton } from "../../Components/Buttons/Button";
import { EmailField } from "../../Components/Inputs/EmailField";
import { Link, RouteComponentProps } from "@reach/router";
import GoogleButton from "../../Components/GoogleButton";
import {
    ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD,
    ROUTE_UNAUTHENTICATED_REGISTER,
} from "../../routes";
import { PasswordField } from "../../Components/Inputs/PasswordField";
import { SignInSchema } from "../../Utils/FormValidationSchema";
import { doSignInAction } from "../../redux/services/signin/signin.actions";
import { ISignInType } from "../../redux/services/signin/interface";
import { RootState } from "../../redux/store";

const SignIn: FC<RouteComponentProps> = () => {
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const signInData: any = useSelector(
        (reduxState: RootState) => reduxState.signin
    );
    const reduxDispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm({
        validationSchema: SignInSchema,
    });

    const onSubmit = async (data: any) => {
        await reduxDispatch(
            doSignInAction({
                signInMethod: ISignInType.EMAIL_PASSWORD,
                email: data.email,
                password: data.password,
            })
        );
    };

    const handleClick: MouseEventHandler = async () => {
        await reduxDispatch(
            doSignInAction({
                signInMethod: ISignInType.SOCIAL_AUTH,
            })
        );
    };

    return (
        <Layout title="Sign in to your account">
            <div>
                <GoogleButton
                  handleClick={handleClick}
                  text="Sign in with Google"
                  loading={isLoading}
                />

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                  className={styles.form}
                >
                    <Grid style={{ marginBottom: 18 }} container spacing={1}>
                        <Grid item xs={12}>
                            <Box className={styles.labelText}>Email Address</Box>
                        </Grid>
                        <EmailField register={register} errors={errors} />
                    </Grid>

                    <Grid style={{ marginBottom: 18 }} container spacing={1}>
                        <Grid item xs={12}>
                            <Box className={styles.labelText}>Password</Box>
                        </Grid>
                        <PasswordField register={register} errors={errors} />
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container>
                            <SubmitButton
                              loading={signInData.status === "pending"}
                              className={styles.submit}
                              text="Sign in"
                            />

                            <Grid
                              style={{ marginTop: "18px", justifyContent: "center" }}
                              container
                            >
                                <Link
                                  style={{ textAlign: "center" }}
                                  to={ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD}
                                  className={styles.link}
                                >
                                    Forgotten Password?
                                </Link>
                            </Grid>

                            <Grid
                              style={{ marginTop: "18px", justifyContent: "center" }}
                              container
                            >
                                <div>
                                    <span className={styles.linkText}>
                                        Don't have an account?
                                    </span>
                                    <Link
                                      to={ROUTE_UNAUTHENTICATED_REGISTER}
                                      className={styles.link}
                                    >
                                        Sign up
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Layout>
    );
};

export default SignIn;
