import React from "react";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Router } from "@reach/router";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";

import {
    ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD,
    ROUTE_UNAUTHENTICATED_LOGIN,
    ROUTE_UNAUTHENTICATED_REGISTER,
    ROUTE_UNAUTHENTICATED_RECRUITER_REGISTER,
    ROUTE_TERMS_AND_CONDITIONS,
    ROUTE_PRIVACY_POLICY,
} from "./routes";
import SignIn from "./Pages/SingIn/SingIn";
import SignUp from "./Pages/SignUp/SignUp";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import theme from "./Theme";
import { store, persistor } from "./redux/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RecruiterSignUp from "./Pages/RecruiterSignUp/RecruiterSignUp";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";

function App() {
    toast.configure({
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });

    return (
        <ErrorBoundary>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Router>
                            <SignIn path={ROUTE_UNAUTHENTICATED_LOGIN} default />
                            <SignUp path={ROUTE_UNAUTHENTICATED_REGISTER} />
                            <RecruiterSignUp
                              path={ROUTE_UNAUTHENTICATED_RECRUITER_REGISTER}
                            />
                            <ResetPassword path={ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD} />
                            <Terms path={ROUTE_TERMS_AND_CONDITIONS} />
                            <Privacy path={ROUTE_PRIVACY_POLICY} />
                        </Router>
                    </ThemeProvider>
                </PersistGate>
            </ReduxProvider>
        </ErrorBoundary>
    );
}

export default App;
