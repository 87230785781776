import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        fontFamily: "Raleway",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "26px",
        letterSpacing: "0.03em",
        color: theme.palette.colors.black
    },
    lgContainer: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "56px",
        lineHeight: "60px",
        letterSpacing: "0.02em",
        color: theme.palette.colors.white
    }
}));

export default useStyles;
