import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CompassButton } from "./CompassButton";

// eslint-disable-next-line import/prefer-default-export
export const SubmitButton = ({
    className,
    text,
    loading,
    disabled,
    size = "medium"
}: {
    className?: string;
    text: string;
    loading?: boolean;
    disabled?: boolean;
    size?: "small" | "medium" | "large";
}) => (
    <CompassButton
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={className}
      endIcon={loading ? <CircularProgress size={18} /> : null}
      disabled={disabled || loading}
      size={size}
    >
        {text}
    </CompassButton>
);
