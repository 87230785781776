/* eslint-disable max-len */
import * as yup from "yup";

export const SignUpSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
        .string()
        .email("Invalid email address")
        // eslint-disable-next-line no-useless-escape
        .matches(/^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,8})?$/,
            "Please use your company email address")
        .required("Email is required"),
    password: yup
        .string()
        .required("Password is required")
        .matches(/(?=.*[a-z])/,
            {
                message: "Password must contain at least 1 lowercase alphabetical character",
                excludeEmptyString: false
            })
        .matches(/(?=.*[A-Z])/,
            {
                message: "Password must contain at least 1 uppercase alphabetical character",
                excludeEmptyString: false
            })
        .matches(/(?=.*[0-9])/,
            {
                message: "Password must contain at least 1 numeric character",
                excludeEmptyString: false
            })
        .matches(/(?=.*[!@#$%^&*])/,
            {
                message: "Password must contain at least one special character",
                excludeEmptyString: false
            })
        .matches(/(?=.{8,})/,
            {
                message: "Password must be eight characters or longer",
                excludeEmptyString: false
            }),
});

export const SignInSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    password: yup
        .string()
        .required("Password is required"),
    remember: yup.boolean().notRequired(),
});

export const ForgotPasswordSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required")
});
