import { createSlice } from "@reduxjs/toolkit";

import {
    doSignUpAction
} from "./signup.actions";
import {
    IState
} from "./interface";

const initialState:IState = {
    status: "",
    response: {}
};

export const authSlice = createSlice({
    name: "signup",
    initialState,
    reducers: {
        increment: (state, action) => {
            // state.candidateList = [1, 2];
            // ✅ CORRECT: logs a plain JS copy of the current data
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(doSignUpAction.pending, (state: IState, action) => {
                state.status = "pending";
            })
            .addCase(doSignUpAction.fulfilled, (state: IState, action: any) => {
                state.status = "fulfilled";
                state.response = action.payload;
            })
            .addCase(doSignUpAction.rejected, (state: IState, action: any) => {
                state.status = "rejected";
                state.response = action.payload;
            });
    }
});

export const { increment } = authSlice.actions;
export default authSlice.reducer;
