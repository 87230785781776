import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    contentArea: {
        display: "flex",
        flexDirection: "column",
        width: "80%",
        margin: "0 auto",
        paddingTop: "140px",
        "& p, li": {
            lineHeight: "24px",
            fontSize: 16,
            fontFamily: theme.typography.fontFamilyRaleway
        },
        "& .title": {
            color: theme.palette.colors.black,
            fontSize: 22,
            fontFamily: theme.typography.fontFamilySecondary,
            fontWeight: 800
        }
    },
    subTitle: {
        fontSize: 22,
        fontWeight: 600,
        fontFamily: theme.typography.fontFamilySecondary,
        textAlign: "center",
        maxWidth: "60%",
        margin: "0 auto 80px auto"
    },
    titleMain: {
        fontSize: "60px !important",
        fontWeight: 800,
        lineHeight: "1.2em !important",
        maxWidth: 556,
        margin: "40px auto 40px",
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down("sm")]: {
            fontSize: 24,
        },
    },
    contactSection: {
        display: "flex",
        flexDirection: "column"
    },
    link: {
        textDecoration: "none",
        fontFamily: theme.typography.fontFamilyRaleway,
        color: theme.palette.colors.lightDark,
        "&:first-of-type": {
            marginBottom: 8
        },
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));

export default useStyles;
