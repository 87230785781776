import React, { Component } from "react";

interface IErrorBoundaryState {
  hasError: boolean;
  errorMessage?: string;
}

type ErrorBoundaryProps = unknown;

const logErrorToMyService = (error: any) => {
    console.error(error);
};

class ErrorBoundary extends Component<ErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message };
    }

    componentDidCatch(error: any) {
    // You can also log the error to an error reporting service
        logErrorToMyService(error);
    }

    render() {
        const { children } = this.props;
        const { hasError, errorMessage = "No error message" } = this.state;
        if (hasError) {
            return (
                <div>
                    <h3>Oops. Something went wrong!</h3>
                    <details style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</details>
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
