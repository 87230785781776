import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    root: {
        height: 50,
        width: 465,
        backgroundColor: theme.palette.colors.white,
        borderRadius: 6,
        marginBottom: 30,
        "& .MuiButton-label": {
            display: "flex",
            alignItems: "center",
            gap: "20px",
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            textTransform: "capitalize"
        }
    }

}));

export default useStyles;
