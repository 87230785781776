export interface ISignInData {
    email: string
}
export interface ISignUpData {
    email?: string
    password?: string;
    name?: string;
    candidateId?: string;
    firstName?: string;
    lastName?: string;
    signUpMethod?: ISignUpType;
    role: string;
}
export interface IState {
    status: string;
    response: any;
}
export type Timestamp = any;
export interface IClientLog {
    name: string;
    created_at: Timestamp;
}
export interface IRecruiter {
    id?: string;
    name?: string;
    email: string;
    firstName: string;
    lastName: string;
    slack_id?: string;
    original_avatar_url?: string;
    thumbnail_avatar_url?: string;
    is_active: boolean;
    has_been_onboarded?: boolean;
    bio?: string;
    last_client_name?: string;
    client_name_logs?: IClientLog[];
    linkedin_url?: string;
    saved_candidates?: any; // @TODO add interface for this
    touched_candidates?: any;
    candidate_from_website_count?: number;
    candidate_from_extension_count?: number;
    calendly_link?: string;
    // eslint-disable-next-line max-len
    created_at?: Timestamp | any; // @TODO we should remove any, I had error with Timestamp and we should find solution
    updated_at?: Timestamp | any; // @TODO we should remove any, I had error with Timestamp and we should find solution
}

// eslint-disable-next-line no-shadow
export enum ISignUpType {
    EMAIL_PASSWORD = "EMAIL_PASSWORD",
    SOCIAL_AUTH = "SOCIAL_AUTH",
}

// eslint-disable-next-line no-shadow
export enum USER_ROLES {
    CLIENT = "CLIENT",
    ADMIN = "ADMIN",
    RECRUITER = "RECRUITER"
}
