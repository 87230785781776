import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "../../../firebase";
import { toast } from "react-toastify";

export const doForgotPasswordAction = createAsyncThunk("forgotPassword", async (payload: any) => {
    try {
        if (payload.email) {
            const response = auth.sendPasswordResetEmail(payload.email);
            response.then(() => {
                toast.success("Email has been send successfully!");
            }).catch(() => {
                toast.error("User not found!");
            });
            return response;
        }
    } catch (error) {
        toast.error("User not found!");
        return error;
    }
});
