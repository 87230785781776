import React, { FC } from "react";
import useStyles from "./styles";
import { ReactComponent as Logo } from "../../Assets/Logo.svg";
import { Link, useNavigate } from "@reach/router";
import { ROUTE_UNAUTHENTICATED_REGISTER } from "../../routes";

const NavigationMenu: FC = () => {
    const styles = useStyles();
    const navigate = useNavigate();

    const handleClick = () => navigate(ROUTE_UNAUTHENTICATED_REGISTER);

    return (
        <div className={styles.mainMenu}>
            <div className={styles.root}>
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={handleClick}
                  onClick={handleClick}
                  className={styles.logoContainer}
                >
                    <Logo />
                </div>

                <div className={styles.linksContainer}>
                    <Link className={styles.link} to={ROUTE_UNAUTHENTICATED_REGISTER}>
                        Get Started
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NavigationMenu;
